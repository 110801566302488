import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import AgentEdit from "@components/agents/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "agents.edit"
}

const AgentEditPage = (props) => (
  <>
    <Seo title="Edit Agent" />
    <div className="main-wrapper">
      <Sidebar menuActive="agents" 
        action="Edit"/>
      <AgentEdit
        menu="Edit Agents" 
        parentPath="agents" 
        pageType="Edit"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default AgentEditPage;
